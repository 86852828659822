import React, { useRef, useEffect, useState } from "react";
import { navigate } from "gatsby";
import Swiper from "react-id-swiper";
import { SwiperSlide } from "swiper/react";
import styles from "../styles/bocado-home-content.module.scss";
import emptyBulletPoint from "../svg/bullet-point-empty.svg";
import selectedBulletPoint from "../svg/bullet-point-selected.svg";
import leftArrow from "../svg/bocado-arrow-left-dark.svg";
import rightArrow from "../svg/bocado-arrow-right-dark.svg";
import ReactPlayer from "react-player";
import "swiper/scss";
// import "swiper/scss/pagination"
// import "swiper/scss/navigation"
// import "swiper/scss/scrollbar"

const BocadoHomeContent = ({ content }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const swiperRef = useRef(null);

    const goNext = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <div className={styles.bocadoContentContainer}>
            {currentIndex !== 0 && (
                <img
                    src={leftArrow}
                    alt="Previous Video"
                    className={styles.bocadoContentLeftArrowButton}
                    onClick={goPrev}
                />
            )}
            {currentIndex !== content.length - 1 && (
                <img
                    src={rightArrow}
                    alt="Next Video"
                    className={styles.bocadoContentRightArrowButton}
                    onClick={goNext}
                />
            )}
            <Swiper
                containerClass={styles.bocadoContentSwiperContainer}
                slidesPerView={1}
                on={{
                    slideChange: (data) => {
                        setCurrentIndex(data.activeIndex);
                    },
                }}
                ref={swiperRef}
            >
                {content.map((item) => {
                  
                    return (
                        <SwiperSlide>
                            <div className={styles.bocadoContentVideoContainer}>
                                {/* <iframe
                                className={styles.bocadoContentVideo}
                                src={`https://www.youtube.com/embed/${item.video.slice(
                                    -11
                                )}`}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                
                            ></iframe> */}

                                <ReactPlayer
                                    onStart={() => {
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Video",
                                            event_action: `Play`,
                                            event_label: `${item.video}`,
                                            interaction: true,
                                        });
                                    }}
                                    onEnded={() => {
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Video",
                                            event_action: `Completed`,
                                            event_label: `${item.video}`,
                                            interaction: true,
                                        });
                                    }}
                                    controls={1}
                                    width="100%"
                                    height="100%"
                                    url={`https://www.youtube.com/embed/${item.video.slice(
                                        -11
                                    )}`}
                                />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className={styles.bocadoContentBulletPointsContainer}>
                {content.map((item, index) =>
                    index === currentIndex ? (
                        <img
                            src={selectedBulletPoint}
                            alt="Bullet Point"
                            className={
                                index !== content.length - 1
                                    ? styles.bocadoContentBulletPoint
                                    : styles.bocadoContentLastBulletPoint
                            }
                        />
                    ) : (
                        <img
                            src={emptyBulletPoint}
                            alt="Bullet Point"
                            className={
                                index !== content.length - 1
                                    ? styles.bocadoContentBulletPoint
                                    : styles.bocadoContentLastBulletPoint
                            }
                        />
                    )
                )}
            </div>
            <button
                className={styles.bocadoContentButton}
                onClick={() => navigate("/contenidos")}
            >
                Ver videos
            </button>
        </div>
    );
};

export default BocadoHomeContent;
