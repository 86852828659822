import * as React from "react";
import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useSwipeable } from "react-swipeable";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import styles from "../styles/bocado-home-carousel.module.scss";
import watermark from "../svg/home-watermark-logo.svg";
import arrowLeft from "../svg/arrow-left.svg";
import arrowRight from "../svg/arrow-right.svg";
import {
    isMobileAtom,
    // hiddenElementsAtom
} from "../recoil/atoms.js";

const BocadoHomeCarousel = ({ contentArray, setShowNewsletterModal }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const isMobile = useRecoilValue(isMobileAtom);
    // const hiddenElements = useRecoilValue(hiddenElementsAtom)

    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => previousSlide(),
    });

    const nextSlide = () => {
        window.dataLayer.push({
            event: "GAEvent",
            event_category: "Carousel",
            event_action: `${currentPage}::${
                contentArray[currentPage - 1].title
            }`,
            event_label: "Siguiente",
            interaction: true,
            component_name: "boton_siguiente_carousel",
            element_text: "Siguiente",
        });
        currentPage === contentArray.length
            ? setCurrentPage(1)
            : setCurrentPage(currentPage + 1);
    };

    const previousSlide = () => {
        window.dataLayer.push({
            event: "GAEvent",
            event_category: "Carousel",
            event_action: `${currentPage}::${
                contentArray[currentPage - 1].title
            }`,
            event_label: "Atrás",
            interaction: true,
            component_name: "boton_atras_carousel",
            element_text: "Atras",
        });
        currentPage === 1
            ? setCurrentPage(contentArray.length)
            : setCurrentPage(currentPage - 1);
    };

    const desktopImagesArray = contentArray.map((item) =>
        getImage(item.carousel_desktop_image)
    );
    const mobileImagesArray = contentArray.map((item) =>
        getImage(item.carousel_mobile_image)
    );

    useEffect(() => {
        // console.log("currentPage", currentPage)
        const carouselTimeout = setTimeout(function () {
            if (currentPage === contentArray.length) setCurrentPage(1);
            else setCurrentPage(currentPage + 1);
        }, 6000);
        return () => clearTimeout(carouselTimeout);
    }, [currentPage]);

    const Button = ({ link }) => {
        if (link === "https://www.stellaartois.com.ar/#experiencias")
            return (
                <button
                    className={styles.carouselButton}
                    onClick={() => {
                        window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Carousel",
                            event_action: `${currentPage}::${
                                contentArray[currentPage - 1].title
                            }`,
                            event_label: "Ver más",
                            interaction: true,
                            component_name: "boton_ver_mas_carousel",
                            element_text: "Ver más",
                        });
                        setShowNewsletterModal(true);
                    }}
                >
                    Registrarme
                </button>
            );
        else
            return (
                <a
                    href={contentArray[currentPage - 1].linkButton}
                    onClick={() => {
                        window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Carousel",
                            event_action: `${currentPage}::${
                                contentArray[currentPage - 1].title
                            }`,
                            event_label: "Ver más",
                            interaction: true,
                            component_name: "boton_ver_mas_carousel",
                            element_text: "Ver más",
                        });
                    }}
                >
                    <button className={styles.carouselButton}>Ver más</button>
                </a>
            );
    };

    return (
        <div id="inicio">
            <div className={`${styles.carouselContainer}`} {...handlers}>
                <div className={styles.carouselPaginationContainer}>
                    {/* <div
            className={`${styles.carouselPaginationContainer} ${
              hiddenElements &&
              !isMobile &&
              styles.carouselPaginationContainerHidden
            }`}
          > */}
                    <img
                        src={arrowLeft}
                        alt="Arrow Left"
                        className={styles.carouselArrow}
                        onClick={() => previousSlide()}
                    />
                    {contentArray.map((elem, index) => (
                        <a
                            key={index}
                            onClick={() => setCurrentPage(index + 1)}
                            className={`${styles.carouselPageNumber} ${
                                index + 1 === currentPage && styles.active
                            }`}
                        >
                            {"0" + (index + 1)}
                        </a>
                    ))}
                    <img
                        src={arrowRight}
                        alt="Arrow Right"
                        className={styles.carouselArrow}
                        onClick={() => nextSlide()}
                    />
                </div>
                <StaticImage
                    src="../images/Carousel/background.jpg"
                    height={1080}
                    quality={95}
                    formats={["AUTO"]}
                    placeholder="blurred"
                    alt="Background"
                    className={styles.carouselBackground}
                />
                <div className={styles.carouselLeftHalfContainer}>
                    <div className={styles.carouselLeftHalfContent}>
                        {/* <div
              className={`${styles.carouselLeftHalfContent} ${
                hiddenElements &&
                !isMobile &&
                styles.carouselLeftHalfContentHidden
              }`}
            > */}
                        <div className={styles.carouselTitle}>
                            {contentArray[currentPage - 1].title}
                        </div>
                        <div className={styles.carouselText}>
                            {contentArray[currentPage - 1].paragraph}
                        </div>
                        <Button
                            link={contentArray[currentPage - 1].linkButton}
                            title={contentArray[currentPage - 1].title}
                        />
                        <img
                            src={watermark}
                            alt="Stella Artois"
                            key={currentPage}
                            className={styles.carouselWatermark}
                        />
                    </div>
                </div>
                <div className={styles.carouselBackgroundGradient}></div>
                <div className={styles.carouselImageContainer}>
                    {/* <div
            className={`${styles.carouselImageContainer} ${
              hiddenElements && !isMobile && styles.carouselImageContainerHidden
            }`}
          > */}
                    {contentArray.map((item, index) =>
                        isMobile
                            ? currentPage === index + 1 && (
                                  <GatsbyImage
                                      image={mobileImagesArray[index]}
                                      alt={item.title}
                                      className={styles.carouselImage}
                                  />
                              )
                            : currentPage === index + 1 && (
                                  <GatsbyImage
                                      image={desktopImagesArray[index]}
                                      alt={item.title}
                                      className={styles.carouselImage}
                                  />
                              )
                    )}
                </div>
            </div>
        </div>
    );
};

export default BocadoHomeCarousel;
