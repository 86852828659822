const getProductsFromAPI = async (setProducts) => {
  // https://services.craftsociety.com.ar/v2/products/stella
  const response = await fetch(
    "https://dev-services.craftsociety.com.ar/v2/products/stella",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
  if (response.ok) {
    const data = await response.json()
    setProducts(data.products)
  } else {
    response.text().then((text) => {
      console.error("Error guardando los datos. \n", text)
    })
  }
}

export default getProductsFromAPI
