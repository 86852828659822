import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { graphql } from "gatsby"
import { navigate } from "gatsby"
import { IsMobileDevice } from "../utils/functions"
import Menu from "../components/menu.jsx"
import Footer from "../components/footer.jsx"
import BocadoHomeCarousel from "../components/bocado-home-carousel"
import BocadoHomeContent from "../components/bocado-home-content.jsx"
import BocadoHomeExperiences from "../components/bocado-home-experiences"
// import BocadoHomeStore from "../components/bocado-home-store"
import BocadoHomeAboutUs from "../components/bocado-home-about-us"
import BocadoModal from "../components/bocado-modal"
import BocadoCart from "../components/bocado-cart"
import Loader from "../components/loader.jsx"
import Seo from "../components/seo"
import getProductsFromAPI from "../utils/getProductsFromAPI.js"
import styles from "../styles/home.module.scss"
import {
  showLoaderAtom,
  showNewsletterModalAtom,
  isMobileAtom,
  productsAtom,
  showCartDrawerAtom,
} from "../recoil/atoms.js"

import { window, document } from "browser-monads"

const IndexPage = ({ data }) => {
  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom)
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)
  const [showNewsletterModal, setShowNewsletterModal] = useRecoilState(
    showNewsletterModalAtom
  )
  // const [showExperienceModal, setShowExperienceModal] = useState(false)
  const [showFooterSuccessModal, setShowFooterSuccessModal] = useState(false)
  const [showCartDrawer, setShowCartDrawer] = useRecoilState(showCartDrawerAtom)

  // const [experienceFormTitle, setExperienceFormTitle] = useState("")
  // const [experienceFormText, setExperienceFormText] = useState("")
  const [products, setProducts] = useRecoilState(productsAtom)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    )
  }, [])

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!local && !session) navigate("/agegate")
    else setShowLoader(false)
  }, [])

  useEffect(() => {
    if (!products.length) getProductsFromAPI(setProducts)
  }, [])

  return (
    <>
      <Seo title="Stella Artois" />
      {showLoader && <Loader />}
      <div className={styles.homeContainer}>
        {showNewsletterModal && (
          <BocadoModal
            type="newsletter"
            isMobile={isMobile}
            setShowModal={setShowNewsletterModal}
            title="BIENVENIDO A BOCADO CLUB"
            subtitle="Registrate para poder participar de los premios y poder disfrutar de los beneficios. Enterate de las últimas novedades."
          />
        )}
        {/* {showExperienceModal && (
          <BocadoModal
            type="experience"
            isMobile={isMobile}
            setShowModal={setShowExperienceModal}
            title={experienceFormTitle}
            subtitle={experienceFormText}
          />
        )} */}
        {showFooterSuccessModal && (
          <BocadoModal
            isMobile={isMobile}
            setShowModal={setShowFooterSuccessModal}
            onlySuccess={true}
          />
        )}
        <BocadoCart
          showCartDrawer={showCartDrawer}
          setShowCartDrawer={setShowCartDrawer}
        />
        <Menu
          isMobile={isMobile}
          setShowNewsletterModal={setShowNewsletterModal}
          setShowCartDrawer={setShowCartDrawer}
        />
        <BocadoHomeCarousel
          setShowNewsletterModal={setShowNewsletterModal}
          contentArray={data.allNovedades.nodes}
        />
        <BocadoHomeContent content={data.allCampanas.nodes} />
        <BocadoHomeExperiences
          cardsArray={data.allExperiencias.nodes}
          homePage={true}
          isMobile={isMobile}
          // setShowExperienceModal={setShowExperienceModal}
          // setExperienceFormTitle={setExperienceFormTitle}
          // setExperienceFormText={setExperienceFormText}
          mainComponent={false}
        />
        {/* <BocadoHomeStore products={products} mainComponent={false} /> */}
        <BocadoHomeAboutUs />
        <Footer
          link={"/#inicio"}
          isMobile={isMobile}
          setShowModal={setShowFooterSuccessModal}
        />
      </div>
    </>
  )
}

export default IndexPage

export const data = graphql`
  query IndexQuery {
    allNovedades(
      sort: { fields: position, order: ASC }
      filter: { active: { eq: true } }
    ) {
      nodes {
        linkButton
        paragraph
        position
        textButton
        title
        carousel_mobile_image {
          childImageSharp {
            gatsbyImageData(height: 1920, quality: 70, formats: [AUTO])
          }
        }
        carousel_desktop_image {
          childImageSharp {
            gatsbyImageData(height: 1080, quality: 70, formats: [AUTO])
          }
        }
      }
    }
    allExperiencias(
      filter: { cardActive: { eq: true } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        cardButtonText
        cardOverline
        cardText
        cardTitle
        date
        pageActive
        pageTitle
        pageFormTitle
        pageFormText
        bases
        provinces
        question
        answer
        scriptTitle
        scriptInterest
        experiences_card_image {
          childImageSharp {
            gatsbyImageData(height: 1080, quality: 70, formats: [AUTO])
          }
        }
      }
    }
    allCampanas(
      sort: { fields: position, order: ASC }
      filter: { active: { eq: true } }
    ) {
      nodes {
        id
        image
        infoImage
        overline
        text
        video
        title
      }
    }
  }
`
