import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styles from "../styles/bocado-home-about-us.module.scss"

const BocadoHomeAboutUs = () => {
  return (
    <div className={styles.bocadoAboutUsContainer}>
      <StaticImage
        src="../images/Bocado/SobreStellaFondo.jpg"
        height={1080}
        quality={95}
        formats={["AUTO"]}
        placeholder="blurred"
        alt="Background"
        className={styles.bocadoAboutUsBackground}
      />
      <div className={styles.bocadoAboutUsLeftHalf}>
        <div className={styles.bocadoAboutUsTextContainer}>
          <div className={styles.bocadoAboutUsTitle}>Sobre Stella</div>
          <div className={styles.bocadoAboutUsText}>
            Te invitamos a conocer más sobre Stella Artois.
          </div>
          <Link to="/sobre-stella">
            <button className={styles.bocadoAboutUsButton}>Ver más</button>
          </Link>
        </div>
      </div>
      <div className={styles.bocadoAboutUsImageContainer}>
        <StaticImage
          src="../images/Bocado/SobreStella.jpg"
          height={1080}
          quality={95}
          formats={["AUTO"]}
          placeholder="blurred"
          alt="Stella Artois"
          className={styles.bocadoAboutUsImage}
        />
      </div>
    </div>
  )
}

export default BocadoHomeAboutUs
